export function isInvalidNameOrCompanyName(key, value) {
  const regex = /^[\p{L}\p{M}\s-]{1,}$/u;
  if (!regex.test(value)) {
    return { key, error: I18n.t(`error_invalid_name_or_company_name`) };
  }

  return { key, error: "" };
}

export function isInvalidCompanyName(key, value) {
  if (value.length < 1 || value.length > 200) {
    return { key, error: I18n.t(`error_company_name_length`) };
  }

  return { key, error: "" };
}

export function validateMobilePhone(key, value) {
  const mobileNumberPattern = /^\+([ ()-]*[0-9]){8}([ ()-]*[0-9][ ()-]*)*$/;
  const trimmed_value = value.trim().split(" ").join("");

  if (
    trimmed_value.length < 13 ||
    trimmed_value.length > 15 ||
    !mobileNumberPattern.test(trimmed_value)
  ) {
    return { key, error: I18n.t(`error_invalid_${key}`) };
  }

  return { key, error: "" };
}

export function validateEmailAddress(key, value) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailPattern.test(value)) {
    return { key, error: I18n.t(`error_invalid_${key}`) };
  }
  return { key, error: "" };
}

export function validateUserPassword(key, value) {
  const passwordPattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/,><':;|_~`]).{10,}$/;

  if (!passwordPattern.test(value)) {
    return { key, error: I18n.t(`error_invalid_${key}`) };
  }
  return { key, error: "" };
}

export function validatePasswordConfirmation(key, password, reenteredPassword) {
  if (password !== reenteredPassword) {
    return { key, error: I18n.t(`error_invalid_${key}`) };
  }
  return { key, error: "" };
}
