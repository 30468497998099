import React, { useState } from "react";
import { Form } from "../common/form";
import TextField from "@oriola-origo/core/lib/TextField";
import Autocomplete from "@oriola-origo/core/lib/Autocomplete";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Button from "@oriola-origo/core/lib/Button";
import {
  isInvalidNameOrCompanyName,
  validateEmailAddress,
  validateMobilePhone,
  isInvalidCompanyName,
} from "./form_validation";
import Typography from "@oriola-origo/core/lib/Typography";
import ShowPassword from "../common/show_password";
import { onPasswordChange, onPasswordConfirmationChange } from "./form_utils";
import PasswordHints from "./components/password_hints";

export const onNameChange = (e, setFormErrors, errorKey) => {
  const { value } = e.target;
  const { key, error } = isInvalidNameOrCompanyName(errorKey, value);
  setFormErrors((prevState) => ({
    ...prevState,
    [key]: error,
  }));
};

export const onCompanyNameChange = (e, setFormErrors, errorKey) => {
  const { value } = e.target;
  const { key, error } = isInvalidCompanyName(errorKey, value);
  setFormErrors((prevState) => ({
    ...prevState,
    [key]: error,
  }));
};

export const onProfessionalTitleChange = (option, setUserProfessionalTitle) => {
  const value = Number(option.value);
  setUserProfessionalTitle((prevState) => ({
    ...prevState,
    signup_professional_title_claim: value ? value : null,
  }));
};

export const onMobilePhoneChange = (e, setFormErrors) => {
  const { value } = e.target;
  const { key, error } = validateMobilePhone("mobile_phone", value);
  setFormErrors((prevState) => ({
    ...prevState,
    [key]: error,
  }));
};

export const onEmailAddressChange = (e, setFormErrors) => {
  const { value } = e.target;
  const { key, error } = validateEmailAddress("email_address", value);
  setFormErrors((prevState) => ({
    ...prevState,
    [key]: error,
  }));
};

export const togglePasswordHints = (value, setShowPasswordHints) => {
  setShowPasswordHints((prevState) => ({
    ...prevState,
    show_password_hints: value,
  }));
};

function UserSignup({
  invitation_code,
  password_chars,
  password_length,
  professional_titles,
  user,
  disabled,
}) {
  const [formErrors, setFormErrors] = useState([]);
  const [userProfessionalTitle, setUserProfessionalTitle] = useState([]);
  const [typedUserPassword, setTypedUserPassword] = useState("");
  const [showPasswordHints, setShowPasswordHints] = useState(false);

  const withCompanyDetails = !invitation_code;
  const professionalTitleOptions = professional_titles.map((pt) => ({
    value: pt.id,
    label: I18n.t(`professional_title_${pt.str_id}`),
  }));

  const onSuccess = (result) => {
    window.location = result.redirect_url;
  };

  return (
    <Form
      action={Routes.perform_signup_path({
        invitation_code: invitation_code,
      })}
      onSuccess={onSuccess}
    >
      <OriolaThemeProvider>
        <Typography variant="h5" sx={{ lineHeight: "29.65px" }}>
          {I18n.t("sign_up")}
        </Typography>
        <br />
        <div className="row signup-forms">
          <div className="col-12 col-md-6">
            <TextField
              id="user_first_name"
              fullWidth={true}
              name="user[first_name]"
              defaultValue={user.first_name}
              autoComplete="new-password"
              required={true}
              iconName=""
              iconPosition="end"
              label={I18n.t("first_name")}
              maxRows={5}
              minRows={1}
              onChange={(e) => onNameChange(e, setFormErrors, "first_name")}
              error={formErrors["first_name"]}
              helperText={formErrors["first_name"]}
            />
          </div>
          <div className="col-12 col-md-6">
            <TextField
              id="user_last_name"
              fullWidth={true}
              name="user[last_name]"
              defaultValue={user.last_name}
              autoComplete="new-password"
              required={true}
              iconName=""
              iconPosition="end"
              label={I18n.t("last_name")}
              maxRows={5}
              minRows={1}
              onChange={(e) => onNameChange(e, setFormErrors, "last_name")}
              error={formErrors["last_name"]}
              helperText={formErrors["last_name"]}
            />
          </div>
          {withCompanyDetails && (
            <div className="col-12">
              <TextField
                id="user_signup_company_claim"
                fullWidth={true}
                name="user[signup_company_claim]"
                defaultValue={user.signup_company_claim}
                autoComplete="new-password"
                required={true}
                iconName=""
                iconPosition="end"
                label={I18n.t("company")}
                maxRows={5}
                minRows={1}
                onChange={(e) =>
                  onCompanyNameChange(e, setFormErrors, "signup_company_claim")
                }
                error={formErrors["signup_company_claim"]}
                helperText={formErrors["signup_company_claim"]}
              />
            </div>
          )}
          {withCompanyDetails && (
            <div className="col-12 col-md-6">
              <input
                type="hidden"
                name="user[signup_professional_title_claim]"
                defaultValue={
                  userProfessionalTitle.signup_professional_title_claim
                }
                autoComplete="new-password"
              />
              <Autocomplete
                autoComplete="cc-name"
                id="title-select"
                options={professionalTitleOptions}
                label={I18n.t("professional_title")}
                autoHighlight
                onChange={(e, value) =>
                  onProfessionalTitleChange(value, setUserProfessionalTitle)
                }
                getOptionLabel={(option) => option.label}
                value={
                  professionalTitleOptions.find(
                    (o) =>
                      o.value ===
                      Number(
                        userProfessionalTitle.signup_professional_title_claim
                      )
                  ) || null
                }
              />
            </div>
          )}
          <div className="col-12 col-md-6">
            <TextField
              id="user_mobile_phone"
              fullWidth={true}
              name="user[mobile_phone]"
              defaultValue={user.mobile_phone}
              autoComplete="new-password"
              required={true}
              iconName=""
              iconPosition="end"
              label={I18n.t("mobile_phone")}
              maxRows={5}
              minRows={1}
              onChange={(e) => onMobilePhoneChange(e, setFormErrors)}
              error={formErrors["mobile_phone"]}
              helperText={I18n.t("signup_mobile_instruction")}
            />
          </div>
          <div className="col-12">
            {invitation_code && (
              <input
                type="hidden"
                name="user[email_address]"
                defaultValue={user.email_address}
                autoComplete="new-password"
              />
            )}
            <TextField
              id="user_email_address"
              fullWidth={true}
              name="user[email_address]"
              defaultValue={user.email_address}
              disabled={invitation_code}
              autoComplete="new-password"
              required={true}
              iconName=""
              iconPosition="end"
              label={I18n.t("email_address")}
              maxRows={5}
              minRows={1}
              onChange={(e) => onEmailAddressChange(e, setFormErrors)}
              error={formErrors["email_address"]}
              helperText={formErrors["email_address"]}
            />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="mb-3 mb-md-0 col-12 col-md-6">
                <ShowPassword
                  name="user[password]"
                  placeHolder={I18n.t("password")}
                  isErrorKey={!!formErrors["user_password"]}
                  onValueChange={(value) =>
                    onPasswordChange(value, setFormErrors, setTypedUserPassword)
                  }
                  onFocusChange={() =>
                    togglePasswordHints(true, setShowPasswordHints)
                  }
                  onBlurChange={() =>
                    togglePasswordHints(false, setShowPasswordHints)
                  }
                  isRequired={true}
                />
              </div>
              <div className="mb-0 col-12 col-md-6">
                <ShowPassword
                  name="user[password_confirmation]"
                  placeHolder={I18n.t("retype_password")}
                  isErrorKey={!!formErrors["password_confirmation"]}
                  helperText={"error_invalid_password_confirmation"}
                  onValueChange={(value) =>
                    onPasswordConfirmationChange(
                      value,
                      setFormErrors,
                      typedUserPassword.typed_user_password
                    )
                  }
                  onFocusChange={() =>
                    togglePasswordHints(false, setShowPasswordHints)
                  }
                  onBlurChange={() =>
                    togglePasswordHints(false, setShowPasswordHints)
                  }
                  isRequired={true}
                />
              </div>
            </div>

            {showPasswordHints.show_password_hints && (
              <PasswordHints
                password_chars={password_chars}
                typedUserPassword={typedUserPassword?.typed_user_password}
              />
            )}
          </div>
          <div className="col-12">
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              disabled={disabled}
              style={{ width: "100%", padding: "10px" }}
            >
              {I18n.t("sign_up")}
            </Button>
          </div>
        </div>
        <hr className="mt-4" />
        <div className="text-center">
          <p>{I18n.t("already_got_an_account_question")}</p>
          <a href={Routes.login_path()}>{I18n.t("log_in")}</a>
        </div>
      </OriolaThemeProvider>
    </Form>
  );
}

export default UserSignup;
